import http from '../http-common'

class Helper {
  riquierdvalidation (data) {
    if (data == null || data === '') {
      return true
    } else {
      return false
    }
  }

  numircvalidation (data) {
    if (isNaN(data)) {
      return true
    } else if (!isNaN(data) || data == null || data === '') {
      return false
    } else {
      return false
    }
  }

  numircriquierdvalidation (data) {
    if (isNaN(data)) {
      return true
    } else if (!isNaN(data) && data != null && data !== '') {
      return false
    } else {
      return true
    }
  }

  validateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !(re.test(email))
  }

  validatePhone (phone) {
    const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,8}$/im
    return !(re.test(phone))
  }

  todayDate () {
    var today = new Date()
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    return date
  }

  currentDateTime () {
    var now = new Date()
    return now.toISOString()
  }

  formatDate (dateString, FullDate = true) {
    if (FullDate) {
      const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return new Date(dateString).toLocaleDateString('ar-EG', options)
    } else {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
      return new Date(dateString).toLocaleDateString('ar-EG', options)
    }
  }

  b64toBlob (b64Data, contentType = '', sliceSize = 512, fileName) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
  }

  printHtml (data, fileName) {
    var myWindow = window.open('', 'print', 'width=600,height=600')
    if (fileName) {
      myWindow.document.title = fileName
    }
    myWindow.document.write(data)
    myWindow.document.close()
    myWindow.focus()
    myWindow.print()
  }

  openAndPrintInvoice (basePath, invID, width = null) {
    var myWindow
    if (width == null) {
      myWindow = window.open(basePath + 'Bill/PrintInvoice?inv_id=' + invID, 'print')
      myWindow.focus()
    } else {
      myWindow = window.open(basePath + 'Bill/PrintInvoice?inv_id=' + invID + '&WidthCm=' + width, 'print')
      myWindow.focus()
    }
  }

  openAndPrintHtml (apiPath, params, name, self) {
    // var UrlPrams = new URLSearchParams(prams).toString()
    http.get(http.defaults.baseURL + apiPath, { params }).then(response => {
      this.printHtml(response.data, (name + this.currentDateTime().toString()))
    },
    ).catch(err => {
      if (err.response.status === 401) {
        self.showToast(self.$t('generic.errorYouDonotHaveAccess'), {
          icon: 'fa-exclamation',
          position: 'bottom-center',
          duration: 2500,
          type: 'error',
        })
      }
      console.error(err)
      self.loading = false
      self.showToast(self.$t('generic.error'), {
        icon: 'fa-exclamation',
        position: 'bottom-center',
        duration: 2500,
        type: 'error',
      })
    })
    // var myWindow = window.open(BillService.getbasePath() + apiPath + '?' + UrlPrams, 'printReports')
    // myWindow.focus()
  }

  downloadtableascsv (tableId, separator = ',') {
    // Select rows from table_id
    var rows = document.querySelectorAll('table#' + tableId + ' tr')
    // Construct csv
    var csv = []
    for (var i = 0; i < rows.length; i++) {
      var row = []; var cols = rows[i].querySelectorAll('td, th')
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""')
        // Push escaped string
        row.push('"' + data + '"')
      }
      csv.push(row.join(separator))
    }
    var csvString = csv.join('\n')
    // Download it
    var filename = 'export_' + tableId + '_' + new Date().toLocaleDateString() + '.csv'
    var link = document.createElement('a')
    link.style.display = 'none'
    link.setAttribute('target', '_blank')
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString))
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  base64ToArrayBuffer (base64, type) {
    var binaryString = window.atob(base64)
    var binaryLen = binaryString.length
    var bytes = new Uint8Array(binaryLen)
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    var pdfFile = new Blob([bytes], {
      type: type,
    })
    var pdfUrl = URL.createObjectURL(pdfFile)
    const win = window.open(pdfUrl, '_blank ')
    win.focus()
    win.print()
    setTimeout(() => {
      win.focus()
      win.print()
    }, 2000)
  }
}

export default new Helper()
