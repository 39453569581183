<template>
  <div class="contanier">

    <!-- <div class="row pb-3">
      <va-collapse class="w-100">
        <span slot="header" class="px-3"> {{$t('tables.labelChooseCraitaria')}} </span>
        <div slot="body">
          <top-Filter class="flex md12" :filterTitle="$t('tables.analytics')" :listOfStringsAndNumbers="filterFileds"
          ></top-Filter>
        </div>
      </va-collapse>

    </div> -->

    <va-card :title="$t('tables.routes')" class="w-100">
      <va-button
        icon="fa fa-plus"
        :title="$t('modal.add')"
        flat
        color="secondary"
        slot="actions"
        class="mr-0"
        @click="showCreateModal"
      />
      <va-data-table
        :fields="fields"
        :data="dataTable"
        :loading="loading"
        :totalPages="totalPages"
        @page-selected="readItems"
        :no-pagination = "searchBool"
        api-mode
        hoverable
      >
        <template slot="marker" slot-scope="props">
          {{(props.rowIndex + 1) + ((pageNumber  -1) *10) }}
        </template>
        <template slot="DeafultTime" slot-scope="props">
          {{(props.rowData.DeafultTime/60).toFixed(2)+ $t('minuits')}}
        </template>
        <template slot="distance" slot-scope="props">
          {{(props.rowData.distance/1000).toFixed(2)+ $t('KM')}}

        </template>
        <template slot="AlertAfter" slot-scope="props">
          {{props.rowData.AlertAfter + $t('minuits')}}
        </template>
        <template slot="iSPrimary" slot-scope="props">
          {{props.rowData.iSPrimary  ? '&#10004;': '&#10008;' }}
        </template>
        <template slot="actions" slot-scope="props">
          <!-- <va-button flat small color="dark" icon="fa fa-key" :title="$t('modal.RestPassword')" @click="showRestModal(props.rowData)" class="ma-0">
          </va-button>-->

          <va-button flat small color="gray" icon="fa fa-edit" :title="$t('modal.edit')"  @click="showEditModal(props.rowData)" class="ma-0">
          </va-button>
          <va-button flat small color="danger" icon="fa fa-trash" :title="$t('modal.delete')"  @click="showDeleteModal(props.rowData)" class="ma-0">
          </va-button>
        </template>
      </va-data-table>
    </va-card>

    <va-modal v-model="deleteModal" no-outside-dismiss
      :title=" $t('generic.deleteItemTitle') "
      :okText=" $t('modal.delete') "
      :cancelText=" $t('modal.cancel') "
      :message=" $t('generic.deleteItemMsg') "
      @ok="okDeletefun()"
      @cancel="cancleDeletefun()">
    </va-modal>
    <va-modal v-model="createModal" no-outside-dismiss
      :size="editModal ? 'normal':'large'"
      :title="$t(modelTitle)"
      :fullscreen="editModal"
      @cancel="editModal? cancleEditfun() : cancleCreatefun()"
      hide-default-actions >
      <form @submit.prevent="editModal? okEditfun()  :okCreatefun()">
        <div class="row flex-center" v-if="!editModal">
          <div class="flex md6 sm12 xs12 px-1">
            <label class="input-label"> {{$t('placeholder.RouteName')+' *'}} </label>
            <va-input v-model="currentobject.RouteName"
              :placeholder="$t('placeholder.RouteName')" required/>
            <span class="input-label"> {{$t('placeholder.RouteNameFormat')}} </span>

          </div>
          <div class="flex md6 sm12 xs12 px-1">
            <label class="input-label"> {{$t('placeholder.AlertAfter')}} </label>
            <va-input v-model="currentobject.AlertAfter"
              :placeholder="$t('placeholder.AlertAfter')" required />
            <span class="input-label"> {{$t('placeholder.AlertAfterFormat')}} </span>

          </div>
          <div class="flex md6 sm12 xs12 px-1">
            <label class="input-label"> {{$t('placeholder.FromLocation')+' *'}} </label>
            <va-input v-model="currentobject.FromLocation"
              :error="validateLocaion(currentobject.FromLocation)"
              :error-messages="$t('errorMessage.FromLocation')"
              :placeholder="$t('placeholder.FromLocation')" required/>
            <span class="input-label"> {{$t('placeholder.ExamleLocation')}} </span>

          </div>
          <div class="flex md6 sm12 xs12 px-1">
            <label class="input-label"> {{$t('placeholder.toLocation')+' *'}} </label>
            <va-input v-model="currentobject.toLocation"
              :error="validateLocaion(currentobject.toLocation)"
              :error-messages="$t('errorMessage.FromLocation')"
              :placeholder="$t('placeholder.toLocation')" required/>
            <span class="input-label"> {{$t('placeholder.ExamleLocation')}} </span>

          </div>
          <div class="flex md6 sm12 xs12 px-1">
            <label class="input-label"> {{$t('placeholder.adminemails')}} </label>
            <va-input v-model="currentobject.adEmails"
              :error="validateEmail(currentobject.adEmails)"
              :error-messages="$t('errorMessage.email')"
              :placeholder="$t('placeholder.emails')" required/>
            <span class="input-label"> {{$t('placeholder.adminemailsFormat')}} </span>
          </div>

          <div class="flex md6 sm12 xs12 px-1 text-start">
            <va-checkbox
              :label="$t('placeholder.iSPrimary')"
              v-model="currentobject.iSPrimary"
            />
          </div>
        </div>
        <div class="row" v-else>
          <div id="map" class="google-map fill-height" ref="googleMapWaypoints" />
          <div id="sidebar">
            <p>Total Distance: <span id="total"></span></p>
            <div id="panel"></div>
          </div>
        </div>
        <div class="d-flex justify--center mt-3">
          <va-button type="button" color="gray" flat @click="editModal? cancleEditfun() : cancleCreatefun()">
            {{ $t('modal.cancel') }}
          </va-button>
          <va-button type="submit" color="secondary" :disabled="stopBtn" class="my-0">{{ $t(modelOktext) }}</va-button>
        </div>
      </form>

    </va-modal>
  </div>
</template>

<script>

// import topFilter from '../../../sheard/topFilter.vue'

import bus from '../../../../app/eventBus'
import helper from '../../../../helper/helper'
import firebase from 'firebase'
import * as GoogleMapsLoader from 'google-maps'

export default {
  data () {
    return {
      perPage: 20,
      totalPages: 1,
      modelTitle: '',
      stopBtn: false,
      SpecificEntityFilds: [],
      deleteModal: false,
      editModal: false,
      createModal: false,
      modelOktext: '',
      opjectBeforeEdit: {},
      currentobject: {
      },
      db: firebase.firestore(),
      dataTable: [],
      pageNumber: 1,

      loading: true,

      searchBool: false,
      /* Pricin8g vars */

      /* end vars */
    }
  },
  // components: {
  //   topFilter,
  // },
  computed: {
    fields () {
      return [{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
        title: '#',

      }, {
        name: 'RouteName',
        title: this.$t('tables.headings.RouteName'),
        sortField: 'time',
        width: '250px',
        sortCallBack: 'sortCallBack|RouteName',
      },

      {
        name: '__slot:DeafultTime',
        title: this.$t('tables.headings.DeafultTime'),
        sortField: 'name',
        sortCallBack: 'sortCallBack|DeafultTime',
      }, {
        name: '__slot:distance',
        title: this.$t('tables.headings.distance'),
        sortField: 'name',
        sortCallBack: 'sortCallBack|distance',
      },
      {
        name: 'adEmails',
        title: this.$t('tables.headings.adminEmails'),
        sortField: 'name',
        width: '250px',
        dataClass: 'overflow',
        sortCallBack: 'sortCallBack|adminEmails',
      },
      {
        name: '__slot:AlertAfter',
        title: this.$t('tables.headings.AlertAfter'),
        sortField: 'name',
        sortCallBack: 'sortCallBack|AlertAfter',
      },
      {
        name: '__slot:iSPrimary',
        title: this.$t('tables.headings.isprimary'),
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filterFileds () {
      return [{
        isNum: false,
        isString: false,
        isBoolean: false,
        isDate: true,
        showLabel: true,
        name: 'time',
        class: '',
        config: { enableTime: true, defaultDate: new Date() },
        title: this.$t('placeholder.time'),
      }, {
        isNum: false,
        isString: true,
        showLabel: true,
        isBoolean: false,
        isDate: false,
        name: 'route',
        title: this.$t('placeholder.route'),
      },
      {
        isNum: true,
        isString: false,
        isBoolean: false,
        isDate: false,
        showLabel: true,
        name: 'avrgTraffic',
        title: this.$t('placeholder.avrgTraffic'),
        step: 0.00001,
        min: 0,
        max: 100,
      },

      ]
    },
  },
  created () {
    document.title = this.$t('generic.Leap') + '-' + this.$t('tables.analytics')
    this.readItems(this.pageNumber)
    bus.$on('SearchStart', (searchObject) => {
      if (!this.loading) {
        this.readItems(1, null, searchObject)
      }
    })
  },

  methods: {
    validateEmail (d = '') {
      if (d === null || d === undefined || d === '') {
        return false
      }
      var emails = d.split(',')
      if (emails.length >= 1) {
        var eerror = false
        emails.forEach(email => {
          eerror = helper.validateEmail(email)
        })
        return eerror
      } else {
        return true
      }
    },
    validateLocaion (d = '') {
      if (d === null || d === undefined || d === '') {
        return false
      }
      var LongLat = d.split(',')
      if (LongLat.length === 2) {
        return !(this.isLatitude(LongLat[0]) && this.isLongitude(LongLat[1]))
      } else {
        return true
      }
    },
    isLatitude (lat) {
      return isFinite(lat) && Math.abs(lat) <= 90
    },
    isLongitude (lng) {
      return isFinite(lng) && Math.abs(lng) <= 180
    },
    formatDate (dateString, FullDate = true) {
      if (FullDate) {
        const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString('ar-EG', options)
      } else {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
        return new Date(dateString).toLocaleDateString('ar-EG', options)
      }
    },
    /*  api get */
    sortCallBack (obj) {
      this.readItems(1, (obj[0].toString().charAt(0).toUpperCase() + obj[0].toString().slice(1)))
    },
    readItems (page = 1, orderBy = null, searchParams = null) {
      this.loading = true
      const firestore = firebase.firestore()
      firestore.collection('routes').where('isRiyadh', '==', false).get().then(querySnapshot => {
        this.dataTable = []
        querySnapshot.forEach((doc, i) => {
          var obj = doc.data()
          obj.id = doc.id
          this.dataTable.push(obj)
        })
        this.loading = false
      })
    },
    create () {
      this.stopBtn = true
      this.loading = true
      GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
      /* We stick to version updates instead of weekly channel update because
       using weekly updates you need to test them, but we prefer to use stable
       versions. */
      GoogleMapsLoader.VERSION = '3.35'
      GoogleMapsLoader.load((google) => {
        const req = {
          origins: [this.getLocationFromString(this.currentobject.FromLocation, google)],
          destinations: [this.getLocationFromString(this.currentobject.toLocation, google)],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          durationInTraffic: true,
          language: 'ar',
          drivingOptions: {
            departureTime: new Date(Date.now()), // for the time N milliseconds from now.
            trafficModel: 'optimistic',
          },
          avoidHighways: false,
          avoidTolls: false,
          avoidFerries: false,

        }
        var service = new google.maps.DistanceMatrixService()
        service.getDistanceMatrix(req).then((results) => {
          this.currentobject.originAddresses = results.originAddresses[0]
          this.currentobject.DeafultTime = results.rows[0].elements[0].duration.value
          this.currentobject.distance = results.rows[0].elements[0].distance.value
          this.currentobject.createAt = new Date(Date.now())
          this.currentobject.UpdateAt = new Date(Date.now())
          this.currentobject.adminEmails = this.currentobject.adEmails.split(',')
          this.currentobject.AlertAfterArr = this.currentobject.AlertAfter.split(',')
          this.currentobject.createBy = this.$store.state.user.email
          this.currentobject.waypoints = []
          this.currentobject.isRiyadh = false

          this.currentobject.UpdateBy = this.$store.state.user.email
          this.db.collection('routes').add(this.currentobject).then(data => {
            this.readItems()
            this.cancleCreatefun()
          })
        })
      })
    },
    edit () {
      this.stopBtn = true
      this.loading = true
      this.db.collection('routes').doc(this.currentobject.id).set(this.currentobject).then((data) => {
        this.readItems()
        this.createModal = false
        this.loading = false
        this.currentobject = {}
        this.editModal = false
        this.stopBtn = false
      })
    },
    remove (id) {
      this.loading = true
      this.db.collection('routes').doc(id).delete().then((data) => {
        this.readItems()
        this.cancleDeletefun()
      })
    },
    getLocationFromString (str, GoogleFunction) {
      var latlong = str.split(',')
      var latitude = parseFloat(latlong[0])
      var longitude = parseFloat(latlong[1])
      return new GoogleFunction.maps.LatLng(latitude, longitude)
    },
    showDeleteModal (cuobject) {
      this.deleteModal = true
      this.currentobject = cuobject
    },
    okDeletefun () {
      this.remove(this.currentobject.id)
    },
    cancleDeletefun () {
      this.currentobject = {
      }
      this.loading = false
      this.stopBtn = false
    },
    /* edit model */
    showEditModal (cuobject) {
      this.editModal = true
      this.modelTitle = 'generic.editItemTitle'
      this.modelOktext = 'modal.edit'
      this.createModal = true
      this.opjectBeforeEdit = Object.assign({}, cuobject)
      this.currentobject = cuobject
      this.loading = true
      setTimeout(() => {
        GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
        GoogleMapsLoader.VERSION = '3.35'
        GoogleMapsLoader.load((google) => {
        /* eslint-disable no-new */
          const map = new google.maps.Map(this.$refs.googleMapWaypoints, {
            center: new google.maps.LatLng(21.42453962997633, 39.82571173709037),
            zoom: 11,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          })
          const directionsService = new google.maps.DirectionsService()
          const directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            map,
            panel: document.getElementById('panel'),
          })

          directionsRenderer.addListener('directions_changed', () => {
            const directions = directionsRenderer.getDirections()

            if (directions) {
              this.computeTotalDistance(directions)
            }
          })
          var LongLat = this.currentobject.FromLocation.split(',')
          var org = new google.maps.LatLng(Number(LongLat[0]), Number(LongLat[1]))
          var LongLat1 = this.currentobject.toLocation.split(',')
          var destination = new google.maps.LatLng(Number(LongLat1[0]), Number(LongLat1[1]))
          var waypoints = []
          this.currentobject.waypoints.forEach(point => {
            if (point.place_id) {
              waypoints.push({
                location: { placeId: point.place_id },
              })
            } else {
              waypoints.push({
                location: new google.maps.LatLng(point.latitude, point.longitude),
              })
            }
          })
          this.displayRoute(org, destination, directionsService, directionsRenderer, waypoints)
        })
      }, 1000)
    },
    displayRoute (origin, destination, service, display, waypoints) {
      service
        .route({
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: 'DRIVING',
          durationInTraffic: true,
          language: 'ar',
          drivingOptions: {
            departureTime: new Date(Date.now()), // for the time N milliseconds from now.
            trafficModel: 'optimistic',
          },
        })
        .then((result) => {
          display.setDirections(result)
        })
        .catch((e) => {
          alert('Could not display directions due to: ' + e)
        })
    },
    computeTotalDistance (result) {
      this.currentobject.waypoints = []
      if (!result.request.origin.location) {
        this.currentobject.FromLocation = `${result.request.origin.lat()},${result.request.origin.lng()}`
      }
      if (!result.request.destination.location) {
        this.currentobject.toLocation = `${result.request.destination.lat()},${result.request.destination.lng()}`
      }
      result.request.waypoints.forEach(point => {
        console.log('point', point)
        this.currentobject.waypoints.push({ latitude: point.location.lat(), longitude: point.location.lng() })
      })
      let total = 0
      let duration = 0
      const myroute = result.routes[0]

      if (!myroute) {
        return
      }

      for (let i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value
        duration += myroute.legs[i].duration.value
      }

      this.currentobject.distance = total
      this.currentobject.DeafultTime = duration
      total = total / 1000
      document.getElementById('total').innerHTML = total + ' km'
    },
    okEditfun () {
      this.stopBtn = true
      this.edit(this.currentobject)
    },
    cancleEditfun () {
      Object.assign(this.currentobject, this.opjectBeforeEdit)
      this.createModal = false
      this.loading = false
      this.currentobject = {
      }
      this.editModal = false
      this.stopBtn = false
    },
    /* end edit model */

    /* create model */
    showCreateModal () {
      this.modelTitle = 'generic.creatItemTitle'
      this.modelOktext = 'modal.create'
      this.createModal = true
      this.loading = true
    },
    okCreatefun () {
      this.stopBtn = true
      this.create()
    },
    cancleCreatefun () {
      this.createModal = false
      this.loading = false
      this.currentobject = {
      }
      this.stopBtn = false
    },
    /* end create model */
  },
}

</script>

<style lang="scss" >
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
  }

  i.fa.fa-exclamation {
    color: red !important;
  }

  .va-modal__inner {
    max-width: 100%;
  }

  .unitsNames-width {
    max-width: 177px;
  }

  #sidebar {
    flex-basis: 15rem;
    flex-grow: 1;
    padding: 1rem;
    max-width: 30rem;
    height: 75vh;
    box-sizing: border-box;
    overflow: auto;
  }

  #map {
    flex-basis: 0;
    flex-grow: 4;
    height: 75vh;
  }

  .va-modal__close {
    right: auto;
    left: 1rem;
  }
</style>
